<template>
  <div class="maincomponent">
    <div class="res-box"> {{res}} </div>
    <form @submit.prevent="commit">
      <input type="input"
        placeholder="请填写测试需求"
        v-model="in_data"
      >
      <button>V</button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue"
import axios from "axios"

const res = ref('test')
const in_data = ref("")

function commit() {
  axios.post('/demo', {
    "type": "message",
    "message_type": "group_message",
    "group_id": "77777777",
    "user_id": "66666666",
    "message": in_data.value,
    "sender": {
        "user_id": "66666666",
        "nickname": "质子"
    }
  },{emulateJSON: true},{
    headers: {"Content-Type": "application/json",}
  })
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
  res.value = in_data.value
}

export default {
  name: 'MainComponent',
  props: {
    msg: String
  },
  data() {
    return {
      res,
      in_data,
      commit
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
